.bg-imgSec{
    background: transparent url(app/images/files/CTA-bg.svg) 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 25px;
    background-position: -93px -13px;
    background-size: cover;
    padding-bottom: 110px;
    z-index: 99999;
    padding-top: 36px;
}
/* 
.bg-imgSec h2{
  text-align: left;
  font: normal normal bold 50px/69px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  white-space: nowrap;
}

.bg-imgSec p{
text-align: left;
font: normal normal normal 24px/25px Poppins;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
} */
